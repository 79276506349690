.combobox {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 8px;
}

.combobox-label {
  display: inline-flex;
  width: 100%;
  align-items: center;
  color: var(--colors-label);
  font-size: 16px;
  font-weight: var(--fontWeights-medium);
  line-height: 1.375;
  user-select: none;
}

.combobox-label.required::after {
  margin-top: -5px;
  color: var(--colors-pink);
  content: '∗';
  font-size: 10px;
}

.combobox-input {
  position: relative;
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 4px;
  appearance: none;
  background-color: var(--colors-bg);
  box-shadow: rgb(215 219 223) 0 0 0 1px inset;
  color: var(--colors-fg);
  cursor: default;
  font-size: 16px;
  font-variant-numeric: tabular-nums;
  letter-spacing: 0.025em;
  line-height: 22px;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

.combobox-input.large {
  padding: 17px 40px 17px 12px;
}

.combobox-input.small {
  padding: 10px 40px 10px 12px;
}

.combobox-input:read-only {
  background-color: var(--colors-bgGroup);
}

.combobox-input:disabled {
  background-color: var(--colors-bgGroup);
  color: var(--colors-fgTertiary);
  cursor: not-allowed;
  pointer-events: none;
}

.combobox-input:focus-visible {
  box-shadow:
    inset 0 0 0 1px var(--colors-blue),
    0 0 0 1px var(--colors-blue);
  cursor: text;
}

.combobox-input:disabled::placeholder {
  color: var(--colors-fgTertiary);
}

.combobox-button {
  all: unset;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  height: 100%;
  padding-right: 12px;
}

.combobox-options {
  position: absolute;
  z-index: 101;
  overflow: auto;
  width: 100%;
  max-height: 236px;
  padding: 4px 0;
  border-radius: 4px;
  margin-top: 4px;
  background-color: var(--colors-bg);
  box-shadow: inset 0 0 0 1px rgb(0 0 0 / 5%);
  box-shadow:
    0 10px 15px -3px rgb(0 0 0 / 10%),
    0 4px 6px -4px rgb(0 0 0 / 10%);
  font-size: 16px;
}

.combobox-option {
  position: relative;
  padding: 8px 36px 8px 12px;
  color: var(--colors-fg);
  cursor: default;
  line-height: 22px;
  list-style: none;
}

.combobox-option.active {
  background-color: var(--colors-lightTint);
}

.combobox-option.selected {
  background-color: var(--colors-blue);
  color: white;
  font-weight: var(--fontWeights-semibold);
}

.combobox-option.disabled {
  color: var(--colors-fgTertiary);
  cursor: not-allowed;
  user-select: none;
}
