.place-autocomplete {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 8px;
}

.place-autocomplete__label {
  display: inline-flex;
  align-items: center;
  color: var(--colors-label);
  font-size: 16px;
  font-weight: 500;
  line-height: 1.375;
  user-select: none;
}

.place-autocomplete__label--required::after {
  margin-top: -5px;
  color: var(--colors-pink);
  content: '∗';
  font-size: 10px;
}

.place-autocomplete__label:empty {
  display: none;
}

.place-autocomplete__input {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  padding: 10px 40px;
  border-width: 0;
  border-radius: 4px;
  appearance: none;
  background-color: var(--colors-bg);
  box-shadow: inset 0 0 0 1px rgb(193 200 205);
  color: var(--colors-fg);
  cursor: default;
  font-size: 14px;
  font-variant-numeric: tabular-nums;
  letter-spacing: 0.025em;
  line-height: 1;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

.place-autocomplete__input:read-only {
  background-color: rgb(248 249 250);
}

.place-autocomplete__input_large {
  padding-top: 0;
  padding-bottom: 0;
  font-size: 16px !important;
  line-height: 56px;
}

.place-autocomplete__input:focus {
  box-shadow:
    inset 0 0 0 1px var(--colors-blue),
    0 0 0 1px var(--colors-blue);
  cursor: text;
}

.place-autocomplete__input:disabled {
  background-color: rgb(248 249 250);
  color: rgb(193 200 205);
  cursor: not-allowed;
  pointer-events: none;
}

.place-autocomplete__input:disabled::placeholder {
  color: rgb(215 219 223);
}

.place-autocomplete__input::placeholder {
  color: rgb(136 144 150);
  line-height: 1;
}

.place-autocomplete__location {
  all: unset;
  position: absolute;
  z-index: 1;
  left: 0;
  height: 36px;
  padding-left: 12px;
}

.place-autocomplete__location_large {
  height: 56px;
}

.place-autocomplete__drop {
  all: unset;
  position: absolute;
  z-index: 1;
  right: 0;
  height: 36px;
  padding-right: 12px;
}

.place-autocomplete__drop_large {
  height: 56px;
}

.place-autocomplete__options {
  position: absolute;
  z-index: 101;
  overflow: auto;
  width: 100%;
  max-height: 240px;
  padding: 4px 0;
  border-radius: 4px;
  margin-top: 4px;
  background-color: var(--colors-bg);
  box-shadow: inset 0 0 0 1px rgb(0 0 0 / 5%);
  box-shadow:
    0 10px 15px -3px rgb(0 0 0 / 10%),
    0 4px 6px -4px rgb(0 0 0 / 10%);
  font-size: 16px;
}

.place-autocomplete__options:focus {
  outline: none;
}

.place-autocomplete__option {
  position: relative;
  padding: 8px 36px 8px 12px;
  color: var(--colors-fg);
  cursor: default;
  list-style: none;
}

.place-autocomplete__option_large {
  padding: 12px 36px 12px 12px;
}

.place-autocomplete__option--active {
  background-color: var(--colors-blue);
  color: white;
}

@media screen and (min-width: 686px) {
  .place-autocomplete__input {
    font-size: 14px;
  }

  .place-autocomplete__options {
    font-size: 14px;
  }
}
