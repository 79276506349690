.thumbnail-abic {
  user-select: none;
}

.thumbnail-abic .contract-title {
  font-family: Barlow, system-ui, 'Segoe UI', Rototo, Emoji, Helvetica, Arial, sans-serif;
  font-size: 65px;
  font-weight: 700;
  line-height: 62px;
  width: 491px;
  height: 400px;
  color: #ffffff; /* css variables: --thumbnail-text-color */
  font-style: normal;
  text-align: left;
  text-transform: uppercase;
}

.thumbnail-abic .contract-title em {
  color: #ca9c63; /* css variables: --thumbnail-text-emphasised-color */
  font-style: normal;
}

.thumbnail-abic .contract-title small {
  font-size: 33px;
  line-height: 33px;
  font-weight: 400;
  top: -10px;
  position: relative;
  color: rgba(255, 255, 255, 0.5); /* css variables: --thumbnail-text-small-color */
}

.thumbnail-abic .contract-title p {
  margin-top: 24px;
  font-size: 32px;
  line-height: 32px;
  position: relative;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.5);
  text-transform: uppercase;
}

.thumbnail-abic .masked-line {
  fill: #e0e0e0; /* css variables: --thumbnail-text-masked-color */
}
