.rcInputNumber {
  position: relative;
  box-sizing: border-box;
  border-width: 0;
  border-radius: 4px;
  appearance: none;
  background-color: white;
  box-shadow: inset 0 0 0 1px hsl(207deg 11.1% 85.9%);
  color: #001026;
  cursor: default;
  font-family: inherit;
  font-variant-numeric: tabular-nums;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

.rcInputNumber input {
  position: relative;
  box-sizing: border-box;
  padding: 10px 12px;
  border-width: 0;
  border-radius: 4px;
  appearance: none;
  background-color: white;
  box-shadow: inset 0 0 0 1px hsl(207deg 11.1% 85.9%);
  color: #001026;
  cursor: default;
  font-family: inherit;
  font-size: 16px;
  font-variant-numeric: tabular-nums;
  letter-spacing: 0.025em;
  line-height: 1.35;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

.rcInputNumber.block input {
  width: 100%;
}

.rcInputNumber:focus {
  box-shadow:
    inset 0 0 0 1px #1a7dff,
    0 0 0 1px #1a7dff;
  cursor: text;
}

.hasLeadingElement input {
  padding-left: 40px;
}

.hasTrailingElement input {
  width: 100%;
  padding-right: 70px;
}

.rcInputNumber input:focus {
  box-shadow:
    inset 0 0 0 1px #1a7dff,
    0 0 0 1px #1a7dff;
  cursor: text;
}

.rcInputNumber input::placeholder {
  color: hsl(206deg 6% 56.1%);
  line-height: 1.35;
}

.rcInputNumber input:disabled {
  background-color: hsl(210deg 16.7% 97.6%);
  color: hsl(205deg 10.7% 78%);
  cursor: not-allowed;
  pointer-events: none;
}

.rcInputNumber input:disabled::placeholder {
  color: hsl(207deg 11.1% 85.9%);
}

.rcInputNumber input:read-only {
  background-color: hsl(210deg 16.7% 97.6%);
}

.rcInputNumber input[aria-invalid='true'] {
  box-shadow: inset 0 0 0 1px #ff0073;
}

.paddingRight_50 input {
  padding-right: 56px;
}

.paddingRight_56 input {
  padding-right: 56px;
}

.paddingRight_80 input {
  padding-right: 80px;
}

.paddingRight_92 input {
  padding-right: 92px;
}

.paddingRight_105 input {
  padding-right: 105px;
}

.rcInputNumber.small input {
  font-size: 14px;
}

.rcInputNumber.medium input {
  height: 42px;
  font-size: 16px;
}

.rcInputNumber.large input {
  height: 56px;
}

.rcInputNumber.xlarge input {
  height: 56px;
  font-size: 22px;
  font-weight: 600;
}

.rcInputNumber_align.left input {
  text-align: left;
}

.rcInputNumber_align.center input {
  text-align: center;
}

.rcInputNumber_align.right input {
  text-align: right;
}

.rcInputNumber input[type='number']::-webkit-inner-spin-button,
.rcInputNumber input[type='number']::-webkit-outer-spin-button {
  margin: 0;
  -webkit-appearance: none;
}
